import { EditorSDK, PageData, RouterRef } from '@wix/platform-editor-sdk';
import type { PageRoleId } from '@wix/pricing-plans-router-utils';

const TOKEN = '';
const ROUTER_PREFIX = 'pricing-plans';

// Allows to identify page by it's purpose/role instead of requiring to use page IDs which are unique per site.
const PackagePickerPageRole = 'PricingPlans' as const;
const CheckoutPageRole = 'Checkout' as const;
const ThankYouPageRole = 'ThankYou' as const;
const PaywallPageRole = 'Paywall' as const;

interface InstallOptions {
  connectPaywall?: boolean;
}

export async function installRouter(sdk: EditorSDK, options: InstallOptions) {
  let routerRef = await sdk.document.routers.getByPrefix(TOKEN, { prefix: ROUTER_PREFIX });
  const pages = await sdk.document.pages.getApplicationPages(TOKEN);
  const packagePickerSlug = findUri(pages, 'membership_plan_picker_tpa', 'plans');
  if (!routerRef) {
    const config = { slugs: Object.fromEntries(pages.map((page) => [page.tpaPageId, page.pageUriSEO])) };
    routerRef = await sdk.document.routers.add(TOKEN, { prefix: ROUTER_PREFIX, config });
    await connectAllPagesToRouter(sdk, routerRef, packagePickerSlug);
  } else {
    const routerData = await sdk.document.routers.get(TOKEN, { routerRef });
    if (routerData.prefix !== ROUTER_PREFIX) {
      await sdk.document.routers.update(TOKEN, { routerRef, prefix: ROUTER_PREFIX });
    }
    const installedPageRoles = routerData.pages.flatMap((page) => page.pageRoles);
    if (!installedPageRoles.includes(PackagePickerPageRole)) {
      await connectPackagePickerPageToRouter(sdk, routerRef, packagePickerSlug);
    }
    if (!installedPageRoles.includes(CheckoutPageRole)) {
      await connectCheckoutPageToRouter(sdk, routerRef);
    }
    if (!installedPageRoles.includes(ThankYouPageRole)) {
      await connectThankYouPageToRouter(sdk, routerRef);
    }
    if (options.connectPaywall && !installedPageRoles.includes(PaywallPageRole)) {
      await connectPaywallPageToRouter(sdk, routerRef);
    }
  }
}

export async function uninstallRouter(sdk: EditorSDK) {
  const routerRef = await sdk.document.routers.getByPrefix(TOKEN, { prefix: ROUTER_PREFIX });
  if (routerRef) {
    await sdk.document.routers.remove(TOKEN, { routerRef });
  }
}

function findUri(pages: PageData[], tpaPageId: string, fallback: string): string {
  return pages.find((page) => page.tpaPageId === tpaPageId)?.pageUriSEO ?? fallback;
}

async function connectAllPagesToRouter(
  sdk: EditorSDK,
  routerRef: RouterRef,
  packagePickerSlug: string,
  connectPaywall = false,
) {
  await connectPackagePickerPageToRouter(sdk, routerRef, packagePickerSlug);
  await connectCheckoutPageToRouter(sdk, routerRef);
  await connectThankYouPageToRouter(sdk, routerRef);
  if (connectPaywall) {
    await connectPaywallPageToRouter(sdk, routerRef);
  }
}

const connectThankYouPageToRouter = async (sdk: EditorSDK, routerRef: RouterRef) =>
  connectPageToRouter('Thank You', 'thank_you', sdk, routerRef);

const connectCheckoutPageToRouter = async (sdk: EditorSDK, routerRef: RouterRef) =>
  connectPageToRouter('Checkout', 'checkout', sdk, routerRef);

const connectPaywallPageToRouter = async (sdk: EditorSDK, routerRef: RouterRef) =>
  connectPageToRouter('Paywall', 'paywall', sdk, routerRef);

const connectPackagePickerPageToRouter = async (sdk: EditorSDK, routerRef: RouterRef, slug: string) =>
  connectPageToRouter('membership_plan_picker_tpa', 'pricing_plans', sdk, routerRef, slug);

async function connectPageToRouter(
  tpaPageId: string,
  pageRole: PageRoleId,
  sdk: EditorSDK,
  routerRef: RouterRef,
  slug?: string,
) {
  await sdk.document.routers.pages.connect(TOKEN, {
    routerRef,
    pageRef: await sdk.document.tpa.getPageRefByTPAPageId(TOKEN, { tpaPageId }),
    pageRoles: [pageRole],
    slug,
  });
}
